exports.linkResolver = (doc) => {
  if (doc.type === "home") {
    return `/`;
  }

  if (doc.type === "resource") {
    return `/resources/${doc.uid}/`;
  }

  if (doc.type === "whats_on") {
    return `/whats-on/${doc.uid}/`;
  }

  if (doc.type === "membership") {
    return `/supporters-scheme/${doc.uid}/`;
  }

  // URL for a page type
  if (doc.type === "page" || doc.type === "overview") {
    if (
      doc.data?.parent_page !== undefined &&
      doc.data?.parent_page !== `undefined`
    ) {
      if (
        doc.data?.parent_page?.uid !== null &&
        doc.data?.parent_page?.uid !== undefined &&
        doc.data?.parent_page?.uid !== `undefined`
      ) {
        return `/${doc.data.parent_page.uid}/${doc.uid}/`;
      } else {
        return `/${doc.uid}/`;
      }
    } else {
      return `/${doc.uid}/`;
    }
  }

  return `/${doc.uid}/`;
};
