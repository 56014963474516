import React from "react";
import { Helmet } from "react-helmet";

export const DefaultSEO = () => (
  <Helmet
    htmlAttributes={{ lang: "en-GB" }}
    meta={[
      {
        name: "viewport",
        content:
          "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
      },
    ]}
    link={[
      {
        rel: "preload",
        href: "/fonts/fonts.css",
        as: "style",
      },
      {
        rel: "dns-prefetch",
        href: "https://images.prismic.io/studiovoltaire",
      },
      {
        rel: "preconnect",
        href: "https://images.prismic.io/studiovoltaire",
      },
      {
        rel: "dns-prefetch",
        href: "https://player.vimeo.com",
      },
      {
        rel: "preconnect",
        href: "https://player.vimeo.com",
      },
      {
        rel: "dns-prefetch",
        href: "https://i.vimeocdn.com",
      },
      {
        rel: "preconnect",
        href: "https://i.vimeocdn.com",
      },
      {
        rel: "dns-prefetch",
        href: "https://f.vimeocdn.com",
      },
      {
        rel: "preconnect",
        href: "https://f.vimeocdn.com",
      },
    ]}
  >
    {/* <link rel="stylesheet" href="https://cdn.plyr.io/3.7.3/plyr.css" /> */}
  </Helmet>
);
