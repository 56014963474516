import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  padding: 30px 20px 20px 20px;

  & p {
    margin: 0;
  }

  & .column-right {
    margin: 0 62px 0 0;
  }

  & .address-container {
    & p {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
    }
  }

  & .charity-details-container {
    margin: 10px 0 0 0;

    & p {
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.03em;
    }
  }

  & .arts-council-logo {
    margin: 0 0 25px 0;

    & img {
      max-width: 160px;
      margin: 0 0 0 auto;
    }
  }

  & .mailing-list-social-links-container {
    display: flex;
    flex-direction: row;

    & .join-mailing-list {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;

      margin: 0 20px 0 0;
    }

    & ol {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      & li {
        margin-right: 15px;

        &:last-of-type {
          margin-right: 0;
        }

        & svg {
          width: 20px;
          height: auto;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    & .address-container {
      & p {
        font-size: 14px;
        line-height: 20px;
      }
    }

    & .mailing-list-social-links-container {
      & .join-mailing-list {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 1020px) {
    & .column-left,
    & .column-right,
    & .mailing-list-social-links-container {
      width: 100%;
    }
  }

  @media (max-width: 960px) {
    padding: 30px 20px 40px 20px;

    & .column-right {
      margin: 0;
    }

    & .address-container,
    & .charity-details-container {
      & p {
        font-size: 10px;
        line-height: 12px;
      }
    }

    & .arts-council-logo {
      width: 100%;
      margin: 25px 0;

      & img {
        margin: 0;
      }
    }

    & .mailing-list-social-links-container {
      justify-content: space-between;
      align-items: baseline;

      & .join-mailing-list {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  @media (max-width: 500px) {
    & .address-container,
    & .charity-details-container {
      & p {
        font-size: 12px;
        line-height: 15px;
      }
    }

    & .mailing-list-social-links-container {
      & .join-mailing-list {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
`;

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicMenu {
        data {
          address {
            richText
          }
          arts_council_logo {
            url
            alt
          }
          charity
          social_media_links {
            link {
              url
            }
            logo {
              url
              alt
            }
          }
        }
      }
    }
  `);

  const links = data.prismicMenu.data.social_media_links.map((link, index) => (
    <li key={`single_mobile_external_link_${index}`}>
      <a
        href={link.link.url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={link.logo.alt}
      >
        <img src={link.logo.url} alt={link.logo.alt} />
      </a>
    </li>
  ));

  return (
    <FooterWrapper>
      <div className="column-left">
        <div className="address-container">
          <PrismicRichText field={data.prismicMenu.data.address.richText} />
        </div>

        <div className="charity-details-container">
          <p>{data.prismicMenu.data.charity}</p>
        </div>
      </div>

      <div className="column-right">
        <div className="arts-council-logo">
          <img
            src={data.prismicMenu.data.arts_council_logo.url}
            alt={data.prismicMenu.data.arts_council_logo.alt}
          />
        </div>

        <div className="mailing-list-social-links-container">
          <Link to={`/policies/`} className="join-mailing-list">
            Privacy Policy
          </Link>

          <Link
            to={`/mailing-list/`}
            className="join-mailing-list"
            state={{ from: `website` }}
          >
            Join our Mailing List
          </Link>

          <ol>{links}</ol>
        </div>
      </div>
    </FooterWrapper>
  );
};
