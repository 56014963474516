import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

export const Header = ({ location, isDesktop }) => {
  const data = useStaticQuery(graphql`
    query {
      prismicMenu {
        data {
          left_menu {
            page {
              document {
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicOverview {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicWhatsOnOverview {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicResources {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          right_menu {
            page {
              document {
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicOverview {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }

                ... on PrismicWhatsOnOverview {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicResources {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const leftMenu = data.prismicMenu.data.left_menu.map((page, index) => (
    <li key={`left_menu_item_${page.page.document.id}_${index}`}>
      <Link
        to={page.page.document.url}
        activeClassName="current"
        partiallyActive
      >
        {page.page.document.data.title.text}
      </Link>
    </li>
  ));

  const rightMenu = data.prismicMenu.data.right_menu.map((page, index) => (
    <li key={`right_menu_item_${page.page.document.id}_${index}`}>
      <Link
        to={page.page.document.url}
        activeClassName="current"
        partiallyActive
      >
        {page.page.document.data.title.text}
      </Link>
    </li>
  ));

  return (
    <>
      {isDesktop ? (
        <DesktopMenu
          location={location}
          leftMenu={leftMenu}
          rightMenu={rightMenu}
        />
      ) : (
        <MobileMenu
          location={location}
          leftMenu={leftMenu}
          rightMenu={rightMenu}
        />
      )}
    </>
  );
};
