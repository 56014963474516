import React from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../components/styles/globalStyles";

// Get Urls correct
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../components/utils/linkResolver";

// Components
import { App } from "../components/global/app";
import { DefaultSEO } from "../components/global/default-seo";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export default function Layout({ children }) {
  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <Location>
        {({ location }) => {
          return (
            <Container location={location.pathname}>
              <Normalize />
              <GlobalStyles />
              <DefaultSEO />
              <App location={location.pathname} children={children} />
            </Container>
          );
        }}
      </Location>
    </PrismicProvider>
  );
}
