import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";

const CookieContainer = styled.div`
  & .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 10000;

    padding: 10px 25px;

    display: grid;
    grid-template-columns: auto 205px 42px;
    grid-column-gap: 20px;
    align-items: center;

    background-color: #f1efed;

    & p {
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.025em;

      margin: 0;

      & a {
        text-decoration: underline;
      }
    }

    @media (max-width: 1100px) {
      align-items: flex-start;
    }

    @media (max-width: 768px) {
      padding: 15px 20px;

      font-size: 11px;
      line-height: 14px;

      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    & .cookie-text {
      max-width: 670px;

      @media (max-width: 768px) {
        max-width: 500px;
        margin: 0 0 20px 0;
      }
    }

    & .cookie-container {
      display: inline-block;

      @media (max-width: 768px) {
        margin: 0 10px 0 0;
      }
    }

    & .content-container {
      color: #0059be;

      @media (max-width: 768px) {
        margin: 0 0 20px 0;
      }
    }

    & .cookie-text > p {
      margin: 0;
      color: #0059be;
    }

    & .button-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    & button {
      font-size: 14px;
      line-height: 32px;
      display: block;

      width: fit-content;

      height: 30px;
      padding: 0 20px;

      background: #0059be;
      border: 1px solid #0059be;
      border-radius: 60px;
      color: #f1efed;
      transition: 250ms background-color ease, 250ms color ease;

      &.cookie-button {
        order: 1;
        margin: 0 25px 0 0;
      }

      &#rcc-decline-button {
        order: 2;
      }

      @media (max-width: 1280px) {
        font-size: 12px;
        line-height: 30px;
      }

      @media (max-width: 960px) {
        font-size: 10px;
        height: 22px;
        line-height: 22px;
      }

      @media (max-width: 500px) {
        padding: 0 10px;

        &.cookie-button {
          margin: 0 15px 0 0;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #0b646f;
          background-color: #fff;
        }
      }
    }

    & a.privacy-link {
      border: 1px solid #fff;
      transition: 250ms all ease;

      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.075em;
      padding: 3px 10px;

      transition: 250ms all ease;

      color: #0059be;

      cursor: pointer;
      display: inline-block;

      @media (max-width: 768px) {
        font-size: 10px;
        padding: 2px 5px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #dcede9;
          background-color: #fff;
        }
      }
    }
  }
`;

export const Cookies = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          cookie_text {
            richText
          }
        }
      }
    }
  `);

  return (
    <CookieContainer>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="studio-voltaire-gtm"
        containerClasses="cookie-container"
        contentClasses="content-container"
        buttonWrapperClasses="button-container"
        buttonClasses="cookie-button"
        disableStyles={true}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        expires={150}
      >
        <div className="cookie-text news-gothic">
          <PrismicRichText field={data.prismicMenu.data.cookie_text.richText} />
        </div>
      </CookieConsent>
    </CookieContainer>
  );
};
