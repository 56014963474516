import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import { Script } from "gatsby";

// Prismic
import { linkResolver } from "./src/components/utils/linkResolver";

// Config
const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      overview: React.lazy(() => import("./src/pages/{PrismicOverview.url}")),
      page: React.lazy(() => import("./src/pages/{PrismicPage.url}")),
      resource: React.lazy(() => import("./src/pages/{PrismicResource.url}")),
      whats_on: React.lazy(() => import("./src/pages/{PrismicWhatsOn.url}")),
      archive: React.lazy(() => import("./src/pages/archive")),
      homepage: React.lazy(() => import("./src/pages/index")),
      mailing_list: React.lazy(() => import("./src/pages/mailing-list")),
      resources: React.lazy(() => import("./src/pages/resources")),
      whats_on_overview: React.lazy(() => import("./src/pages/whats-on")),
    },
  },
];

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
);

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Script>
        {`var s = document.createElement("script");
        s.setAttribute("data-account", "FQ5APu0KJp");
        s.setAttribute("src", "https://cdn.userway.org/widget.js");
        (document.body || ddocument.head).appendChild(s);`}
      </Script>
    </>
  );
};
