import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { Cookies } from "../cookies/cookies";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, children }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1040px)");

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <>
      <Helmet
        meta={[
          {
            name: "viewport",
            content:
              "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
          },
        ]}
      />

      {!location.includes(`/mailing-list/`) && (
        <Header location={location} isDesktop={isDesktop} />
      )}
      <Main>{children}</Main>
      {!location.includes(`/mailing-list/`) && <Footer location={location} />}

      <Cookies location={location} />
    </>
  );
};
