import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useFlexSearch } from "react-use-flexsearch";
import { navigate } from "gatsby";
import { useClickAway } from "react-use";

// Icons
import { SearchIcon } from "../icons/menu-icons";

const SearchButton = styled.button``;

const SearchContainer = styled.div`
  height: 50px;
  margin: 0 0 0 auto;

  @media (max-width: 960px) {
    width: 100%;
    height: 30px;

    &.menu-closed {
      position: absolute;
      left: 0;
      right: 0;
      top: 30px;

      background: #fff;

      padding: 0 20px;
    }

    &.menu-open {
      position: absolute;
      left: 0;
      right: 0;
      top: 30px;

      background: #0059be;

      padding: 0 20px;
    }
  }
`;

const SearchForm = styled.form`
  display: grid;
  grid-template-columns: 20px auto;
  grid-column-gap: 10px;

  width: 100%;
  height: 51px;

  & .trigger-search {
    & svg {
      & path {
        transition: 250ms stroke ease;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        & svg {
          & path {
            stroke: ${(props) => props.color};
          }
        }
      }
    }
  }

  & .input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;

    & input {
      padding: 0;
      /* height: 20px; */

      border: 0;

      width: 100%;
      color: ${(props) => props.color};
      border-bottom: 1px solid ${(props) => props.color};

      background-color: transparent;

      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.05em;

      &:focus {
        outline: none;
      }
    }

    & button[type="submit"] {
      font-size: 12px;
      line-height: 15px;

      padding: 5px 0 0 10px;
      text-align: right;
      transition: 250ms color ease;

      @media (max-width: 960px) {
        font-size: 15px;
        padding: 5px 0 5px 5px;
        color: ${(props) => props.color};
      }

      @media (max-width: 500px) {
        font-size: 17px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: ${(props) => props.color};
        }
      }
    }
  }

  @media (max-width: 960px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    height: auto;
    max-width: 100%;

    & button,
    & button[type="submit"] {
      margin: 0;
      padding: 0;

      order: 1;
      align-items: center;

      letter-spacing: 0.05em;
    }

    & .input-container {
      display: grid;
      grid-template-columns: 1fr 20px;
      grid-column-gap: 10px;
      /* justify-content: space-between; */
      align-items: baseline;
      order: 1;

      & input {
        height: auto;
        line-height: 20px;
        width: 100%;
      }
    }

    & button[type="submit"] {
      order: 11;
    }

    & .trigger-search {
      order: 12;
    }
  }
`;

export const Search = ({
  index,
  store,
  isSearchOpen,
  setIsSearchOpen,
  isDesktop,
  fill,
  isMenuOpen,
}) => {
  const [query, setQuery] = useState("");
  const results = useFlexSearch(query, index, store);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsSearchOpen(false);
  });

  const navigateToSearchResultsPage = (event) => {
    event.preventDefault();
    setIsSearchOpen(false);
    navigate(`/search?q=${query}`, {
      state: {
        searchResults: results,
        searchQuery: query,
      },
    });
  };

  useEffect(() => {
    if (typeof window !== undefined && isSearchOpen === true) {
      document.getElementById("search-input").focus();
    }
  }, [isSearchOpen]);

  if (isSearchOpen) {
    if (isDesktop) {
      return (
        <SearchContainer color={fill} ref={ref}>
          <SearchForm onSubmit={navigateToSearchResultsPage} color={fill}>
            <button
              type="submit"
              className="trigger-search"
              aria-label="Submit search"
            >
              <SearchIcon fill={fill} />
            </button>

            <div className="input-container">
              <input
                id="search-input"
                name="query"
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </SearchForm>
        </SearchContainer>
      );
    } else {
      return (
        <>
          <SearchButton
            type="button"
            onClick={() => setIsSearchOpen(false)}
            aria-label="Close search"
          >
            <SearchIcon fill={fill} />
          </SearchButton>

          <SearchContainer
            color={fill}
            className={`menu-${isMenuOpen ? `open` : `closed`}`}
          >
            <SearchForm onSubmit={navigateToSearchResultsPage} color={fill}>
              <div className="input-container">
                <input
                  id="search-input"
                  name="query"
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />

                <button
                  type="submit"
                  className="trigger-search"
                  aria-label="Submit search"
                >
                  →
                </button>
              </div>
            </SearchForm>
          </SearchContainer>
        </>
      );
    }
  } else {
    return (
      <SearchButton
        type="button"
        onClick={() => setIsSearchOpen(true)}
        aria-label="Open search"
      >
        <SearchIcon fill={fill} />
      </SearchButton>
    );
  }
};
