import React from "react";

export const Logo = () => (
  <svg
    width="162"
    height="17"
    viewBox="0 0 162 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M84.0179 0.28418L81.7109 14.1822L79.4064 0.28418H76.2866L79.2751 16.5707H84.1493L87.16 0.28418H84.0179Z"
      fill="#0059BE"
    />
    <path
      d="M96.3188 11.2497V5.6028C96.3188 3.47388 95.5729 2.78058 94.3413 2.78058C93.1321 2.78058 92.3639 3.47633 92.3639 5.6028V11.2497C92.3639 13.3786 93.1098 14.0719 94.3413 14.0719C95.5729 14.0719 96.3188 13.3786 96.3188 11.2497ZM89.1797 11.1835V5.64689C89.1797 1.86923 91.1348 0 94.3413 0C97.5479 0 99.503 1.86678 99.503 5.64689V11.1835C99.503 14.9833 97.5479 16.85 94.3413 16.85C91.1348 16.8525 89.1797 14.9857 89.1797 11.1835Z"
      fill="#0059BE"
    />
    <path
      d="M110.793 16.5707H102.799V0.28418H105.983V13.7902H110.793V16.5707Z"
      fill="#0059BE"
    />
    <path
      d="M110.792 0.28418H119.887V3.0427H116.943V16.5707H113.759V3.0427H110.792V0.28418Z"
      fill="#0059BE"
    />
    <path
      d="M127.222 10.5343L125.926 2.67278L124.63 10.5343H127.222ZM123.49 0.28418H128.367L131.375 16.5707H128.233L127.685 13.2488H124.171L123.621 16.5707H120.501L123.49 0.28418Z"
      fill="#0059BE"
    />
    <path
      d="M137.237 0.28418H134.053V16.5707H137.237V0.28418Z"
      fill="#0059BE"
    />
    <path
      d="M143.916 2.99861V7.36423H145.628C146.815 7.36423 147.561 6.73462 147.561 5.21572V4.93398C147.561 3.52287 146.815 3.00106 145.628 3.00106H143.916V2.99861ZM140.754 0.28418H145.982C148.75 0.28418 150.728 1.76144 150.728 4.51751V5.14712C150.728 7.38383 149.432 8.7092 147.544 9.18692L151.189 16.5707H147.563L143.918 8.55731V16.5707H140.756V0.28418H140.754Z"
      fill="#0059BE"
    />
    <path
      d="M153.711 0.28418V16.5707H161.596V13.7902H156.876V9.62054H161.158V6.84242H156.876V3.0427H161.596V0.28418H153.711Z"
      fill="#0059BE"
    />
    <path
      d="M4.63383 0.0507812C7.11678 0.0507812 9.11404 1.46189 9.11404 4.39436V5.54578H6.06116V4.4605C6.06116 3.26498 5.51104 2.72356 4.63383 2.72356C3.82105 2.72356 3.25112 3.22333 3.25112 4.15672C3.25112 5.242 3.91026 5.80792 4.92128 6.54532L6.80951 7.91234C8.21453 8.95352 9.33458 10.2568 9.33458 12.3637C9.33458 15.3819 7.22581 16.9008 4.65614 16.9008C2.08647 16.9008 0 15.3378 0 12.5352V11.1878H3.07518V12.3833C3.07518 13.5347 3.71202 14.228 4.65614 14.228C5.62255 14.228 6.23709 13.6646 6.23709 12.7287C6.23709 11.5553 5.53335 10.906 4.4802 10.1221L2.68118 8.80163C1.23156 7.76045 0.178415 6.47918 0.178415 4.30616C0.175937 1.26836 2.17567 0.0507812 4.63383 0.0507812Z"
      fill="#0059BE"
    />
    <path
      d="M11.0269 0.333008H20.1185V3.09153H17.1772V16.6196H13.9905V3.09153H11.0269V0.333008Z"
      fill="#0059BE"
    />
    <path
      d="M25.6101 0.333008V11.5606C25.6101 13.4715 26.3138 14.1232 27.476 14.1232C28.6382 14.1232 29.3419 13.4715 29.3419 11.5606V0.333008H32.5261V11.5166C32.5261 15.1203 30.6156 16.9013 27.4735 16.9013C24.3314 16.9013 22.4209 15.1203 22.4209 11.5166V0.333008H25.6101Z"
      fill="#0059BE"
    />
    <path
      d="M42.8516 11.1907V5.76186C42.8516 3.76524 42.1057 3.09153 40.8742 3.09153H39.1173V13.8415H40.8742C42.1057 13.8415 42.8516 13.1898 42.8516 11.1907ZM46.0383 5.80596V11.127C46.0383 14.7969 44.0832 16.622 40.8767 16.622H35.9355V0.333008H40.8767C44.0832 0.333008 46.0383 2.15814 46.0383 5.80596Z"
      fill="#0059BE"
    />
    <path
      d="M52.5162 0.333008H49.332V16.6196H52.5162V0.333008Z"
      fill="#0059BE"
    />
    <path
      d="M62.8193 11.3005V5.65358C62.8193 3.52466 62.0734 2.83136 60.8418 2.83136C59.6326 2.83136 58.8644 3.52711 58.8644 5.65358V11.3005C58.8644 13.4294 59.6103 14.1227 60.8418 14.1227C62.0709 14.1227 62.8193 13.4269 62.8193 11.3005ZM55.6802 11.2343V5.69768C55.6802 1.92001 57.6353 0.0507812 60.8418 0.0507812C64.0484 0.0507812 66.0035 1.91756 66.0035 5.69768V11.2343C66.0035 15.034 64.0484 16.9008 60.8418 16.9008C57.6353 16.9008 55.6802 15.034 55.6802 11.2343Z"
      fill="#0059BE"
    />
  </svg>
);
