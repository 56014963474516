import React from "react";

// Components
import { Search } from "./search";

// Hooks
import { UseSearchData } from "../hooks/useSearchData";

// Icons
import { SearchIcon } from "../icons/menu-icons";

const SearchIndex = ({
  isSearchOpen,
  setIsSearchOpen,
  isDesktop,
  fill,
  isMenuOpen,
}) => {
  const data = UseSearchData();

  if (isSearchOpen && data !== null) {
    return (
      <Search
        index={data.index}
        store={data.store}
        isSearchOpen={isSearchOpen}
        setIsSearchOpen={setIsSearchOpen}
        isDesktop={isDesktop}
        fill={fill}
        isMenuOpen={isMenuOpen}
      />
    );
  } else {
    return (
      <button
        type="button"
        className="search-icon-svg"
        onClick={() => setIsSearchOpen(true)}
        aria-label="Open search"
      >
        <SearchIcon fill={fill} />
      </button>
    );
  }
};

export default SearchIndex;
