import React from "react";

export const BagIcon = () => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00037 0C4.79123 0 3.00037 1.79086 3.00037 4H0V17H14V4H11.0004C11.0004 1.79086 9.20951 0 7.00037 0ZM7.00037 2C8.10494 2 9.00037 2.89543 9.00037 4H5.00037C5.00037 2.89543 5.8958 2 7.00037 2ZM12 6H2V15H12V6Z"
      fill="white"
    />
  </svg>
);

export const SearchIcon = ({ fill }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="5.4523"
      cy="6.1359"
      r="4.6023"
      stroke={fill}
      strokeWidth="1.7"
    />
    <path
      d="M8.38818 9.07178L13.4211 14.1047"
      stroke={fill}
      strokeWidth="1.7"
    />
  </svg>
);

export const MenuToggleIcon = ({ fill }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.81445 7.81482V13.6296H7.81445V7.81482H13.6299V5.81482H7.81445V0H5.81445V5.81482H0.000252767L0.00025268 7.81482H5.81445Z"
      fill={fill}
    />
  </svg>
);
