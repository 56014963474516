import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

//Components
import SearchIndex from "../search/search-index";

// Icons
import { Logo } from "../icons/logo";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 0 40px;
  line-height: 50px;

  background-color: #fff;
  z-index: 200;

  & nav {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;

    & .logo {
      & svg {
        display: block;
      }
    }

    & ol {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      & li {
        font-size: 16px;
        line-height: 50px;
        letter-spacing: 0.02em;

        color: #0059be;

        margin: 0 20px 0 0;

        & a {
          &.current,
          &:hover {
            text-decoration: underline;
            text-underline-offset: 6px;
            /* text-decoration-thickness: 1px; */
          }
        }
      }

      &.menu-right {
        justify-content: flex-end;

        & li {
          &:last-of-type {
            margin: 0;

            & svg {
              margin: 1px 0 -1px 0;
            }
          }

          &.search-icon {
            margin: 0;

            &.is-open {
              width: 100%;
              max-width: 300px;
            }

            & .mobile {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

export const DesktopMenu = ({ leftMenu, rightMenu, location }) => {
  // Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <HeaderWrapper>
      <nav role="navigation" aria-label="Main">
        <ol className="menu-left">{leftMenu}</ol>

        <div className="logo">
          <Link to={`/`} aria-label="Home">
            <Logo />
          </Link>
        </div>

        <ol className="menu-right">
          {!isSearchOpen && (
            <>
              {rightMenu}

              <li>
                <a
                  href="https://houseofvoltaire.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Shop
                </a>
              </li>
            </>
          )}

          <li className={`search-icon ${isSearchOpen ? `is-open` : ``}`}>
            <SearchIndex
              isSearchOpen={isSearchOpen}
              setIsSearchOpen={setIsSearchOpen}
              location={location}
              isDesktop={true}
              fill={`#0059BE`}
              isMenuOpen={false}
            />
          </li>
        </ol>
      </nav>
    </HeaderWrapper>
  );
};
